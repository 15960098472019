<template lang="pug">
  v-container
    v-data-table(
      :items="fdPrmList"
      :headers="headers"
      :loading="fdPrmGetting"
      dense
      single-expand
    )
      template(v-slot:item="{ item, expand, isExpanded }")
        tr
          td {{ item.stock_id }}
          td {{ item.category.description }}
          td {{ item.stock.description || null }}
          td
            v-btn.ma-0.pa-0(
              :color="`${editItem === item.id ? 'blue-grey' : 'yellow darken-3'}`"
              text
              small
              dense
              @click="$emit('input', item)"
            )
              v-icon mdi-pencil
            v-btn.ma-0.pa-0(
              color="purple darken-3"
              text
              small
              dense
              @click="$router.push({ name: 'stocks.feeding-programs.show', params: { feedingProgramId: item.id } })"
            )
              v-icon mdi-eye
            v-btn.ma-0.pa-0(
              color="blue-grey"
              text
              small
              dense
              :class="isExpanded ? 'is-expanded' : ''"
              @click="expand(!isExpanded)"
            )
              v-icon mdi-chevron-down
      template(v-slot:expanded-item="{ item }")
        tr
          td(colspan="4")
            v-row
              v-col(cols="3" align="left")
                span.blue-grey--text.mr-2 Body Weight
                small.blue-grey--text {{ kilogramFormat(item.body_weight || 0) }}
                v-divider
              v-col(cols="3" align="left")
                span.blue-grey--text.mr-2 Range
                small.blue-grey--text {{ item.day_start || 'N/A' }} - {{ item.day_end || 'N/A' }}
                v-divider
              v-col(cols="3" align="left")
                span.blue-grey--text.mr-2 Expected Weight
                small.blue-grey--text {{ kilogramFormat(item.body_weight || 0) }}
                v-divider
</template>
<script>
import fdPrmRepository from '@/repositories/feeding-program.repository'
import { getVars, errorHandler } from '@/libs/api-helper.extra'
import searchDelay from '@/libs/searchDelay.extra'

const getFdPrmDelay = searchDelay()

const tableVars = () => ({
  headers: [
    { text: 'Stock Code', value: 'stock_id' },
    { text: 'Category', value: 'feeding_program_category_id' },
    { text: 'Item', value: 'stock_id' },
    { text: 'Config', sortable: false },
  ],
})

export default {
  name: 'FeedingProgramTable',
  props: {
    value: {
      type: Object,
      default: () => ({ }),
    },
  },
  created () {
    this.getFdPrm()
    this.websocketEvents()
  },
  data () {
    return {
      ...tableVars(),
      ...getVars('fd-prm'),
    }
  },
  computed: {
    editItem () {
      return this.value.id
    },
  },
  methods: {
    getFdPrm () {
      if (this.fdPrmGetting) return
      this.fdPrmGetting = true
      this.fdPrmGetErrors = []
      getFdPrmDelay(() => {
        fdPrmRepository.index()
          .then(({ data }) => { this.fdPrmList = data })
          .catch(e => { this.fdPrmGetErrors = errorHandler(e) })
          .then(() => { this.fdPrmGetting = false })
      })
    },
    websocketEvents () {
      const { echo } = this.$store.state.websocket
      echo.private('database.event')
        .listen('DBStoreEvent', this.dbStoreEvent)
        .listen('DBUpdateEvent', this.dbUpdateEvent)
    },
    dbStoreEvent ({ model, data }) {
      if (!model || this.$objectEmpty(data)) return
      if (model === 'FeedingProgram') {
        this.feedingProgramDBStoreEvent(data)
      }
    },
    dbUpdateEvent ({ model, data }) {
      if (!model || this.$objectEmpty(data)) return
      if (model === 'FeedingProgram') {
        this.feedingProgramDBUpdateEvent(data)
      }
    },
    feedingProgramDBUpdateEvent (data) {
      this.fdPrmList = this.fdPrmList.map(item => {
        if (item.id === data.id) return Object.assign({ ...data })
        return Object.assign({ ...item })
      })
    },
    kilogramFormat (number) {
      if (isNaN(number)) return null
      return number.unitFormat('kilogram')
    },
    feedingProgramDBStoreEvent (data) {
      this.fdPrmList.push(data)
    },
  },
}
</script>
<style lang="scss" scoped>
  .v-btn.is-expanded .v-icon {
    transform: rotate(-180deg);
  }
  .border-right {
    border-right: 1px solid #688491;
  }
</style>
